<template>
	<div v-if="$route.query.show !== '0'" style="background: #f7f7f7">
    <div class="title">
      <div class="title-left">
        <!--        <div :style="{backgroundImage:'url('+data.photo+')'}" class="title-left-photo"></div>-->
        <el-image :fit="'contain'" :preview-src-list="[data.photo]" :src="data.photo" class="title-left-photo" style="width: 200px;height: 280px">
          <div slot="error" style="width: 100%;height: 100%">
            <img alt="" src="../../../../assets/img/default-header.png"/>
          </div>
        </el-image>
        <div class="title-left-text">
          <el-icon class="el-icon-s-custom" style="color:  rgba(28, 78, 253, 1)"></el-icon>
          {{ data.student_name }}
        </div>
      </div>

      <div class="title-right">
        <div class="title-right-title" style="display: flex;width: 100%;align-items: flex-start;margin-top: 60rem">
          <div v-for="(item,index) in fields" :key="item.label" style="display: flex;width: 100%;">
            <div v-if="index>0" style="width: 2rem;height: 42rem;background: #F6F9FF;border-radius: 8rem;"></div>
            <div style="flex: 1;text-align: center">
              <div style="color: #666666">{{ item.label }}</div>
              <div style="margin-top: 16rem">
                <el-icon v-if="index===0" :class="data[item.keyType] == 1 ? 'el-icon-male' : 'el-icon-female'" :style="{color:item.iconColor}"></el-icon>
                <el-icon v-if="index===6" :class="data[item.keyType] == 2 ? 'el-icon-s-claim' : 'el-icon-s-release'" :style="{color:data[item.keyType] == 2 ? 'rgba(35, 195, 67, 1)' : 'rgba(247, 101, 96, 1)'}"></el-icon>
                {{ data[item.key] }}
              </div>
            </div>
          </div>
        </div>

        <div style="min-width: 1056rem;width: 100%;height: 2rem;background: #F6F9FF;border-radius: 8rem;margin-top: 21rem"></div>

        <div style="width: 100%;margin-top: 31rem;padding: 0 24rem;box-sizing: border-box;display: flex">
          <div style="flex: 1;display: flex;height: 224rem;flex-flow: column;justify-content: space-between">
            <div style="display: flex;">
              <div :style="{backgroundImage: 'url('+OSS_URL+'/bf-static/1v1-taro/heart-to-heart-talk/data-card.png)' }" style="margin-right: 70rem;width: 190rem;height: 92rem;border-radius: 6rem; background-repeat: no-repeat;background-size: 100%;padding-top: 20rem;padding-left: 36rem;box-sizing: border-box">
                <div style="font-size: 16rem;font-weight: 400;color: #666666;">是否是独生子女</div>
                <div style="font-size: 16rem;font-weight: 500;color: #333333;margin-top: 16rem">{{ data.only_child }}</div>
              </div>
              <div :style="{backgroundImage: 'url('+OSS_URL+'/bf-static/1v1-taro/heart-to-heart-talk/data-card.png)' }" style="width: 190rem;height: 92rem;border-radius: 6rem; background-repeat: no-repeat;background-size: 100%;padding-top: 20rem;padding-left: 36rem;box-sizing: border-box">
                <div style="font-size: 16rem;font-weight: 400;color: #666666;">家庭类型</div>
                <div style="font-size: 16rem;font-weight: 500;color: #333333;margin-top: 16rem">{{ data.home_type }}</div>
              </div>
            </div>

            <div style="display: flex;">
              <div :style="{backgroundImage: 'url('+OSS_URL+'/bf-static/1v1-taro/heart-to-heart-talk/data-card.png)' }" style="margin-right: 70rem;width: 190rem;height: 92rem;border-radius: 6rem; background-repeat: no-repeat;background-size: 100%;padding-top: 20rem;padding-left: 36rem;box-sizing: border-box">
                <div style="font-size: 16rem;font-weight: 400;color: #666666;">希望学习外语语种</div>
                <div style="font-size: 16rem;font-weight: 500;color: #333333;margin-top: 16rem">{{ data.optional_languages }}</div>
              </div>
              <div :style="{backgroundImage: 'url('+OSS_URL+'/bf-static/1v1-taro/heart-to-heart-talk/data-card.png)' }" style="width: 190rem;height: 92rem;border-radius: 6rem; background-repeat: no-repeat;background-size: 100%;padding-top: 20rem;padding-left: 36rem;box-sizing: border-box">
                <div style="font-size: 16rem;font-weight: 400;color: #666666;">希望学习学科组合</div>
                <div style="font-size: 16rem;font-weight: 500;color: #333333;margin-top: 16rem">{{ data.choose_subject }}</div>
              </div>
            </div>
          </div>


          <div style="flex: 1;display: flex;flex-wrap: wrap;justify-content: space-between;height: 224rem;align-items: flex-start;flex-flow: column">
            <div style="display: flex;width: 100%">
              <div style="display: flex;flex-flow: column;align-items: center;justify-content: center">
                <div style="width: 8rem;height: 8rem;background: #D8D8D8;border-radius: 50%"></div>
                <div style="height: 88rem;width: 2rem;background: #F6F9FF;border-radius: 8rem;margin-top: 12rem"></div>
              </div>
              <div style="margin-left: 15rem;width: 100%;">
                <div style="padding-left: 15rem;padding-right: 30rem;display: flex;justify-content: space-between;width: 100%">
                  <div style="font-size: 16rem;font-weight: 500;color: #333333;">是否有身体疾病</div>
                  <div style="font-size: 16rem;font-weight: 400;color: #3D3D3D;">
                    {{ data.disease_history }}
                    <el-icon :class="data.disease_history ==='是'?'el-icon-success':'el-icon-remove'" :style="{marginLeft: '10rem',color:data.disease_history ==='是'?'rgba(35, 195, 67, 1)':'rgba(246, 173, 60, 1)'}" size="16"></el-icon>
                  </div>
                </div>
                <div style="height: 66rem;background: #F6F9FF;border-radius: 4rem;padding: 12rem;font-size: 14rem;font-weight: 400;color: #666666;margin-top: 12rem">
                  {{ data.disease_details }}
                </div>
              </div>
            </div>
            <div style="display: flex;width: 100%">
              <div style="display: flex;flex-flow: column;align-items: center;justify-content: center">
                <div style="width: 8rem;height: 8rem;background: #D8D8D8;border-radius: 50%"></div>
                <div style="height: 88rem;width: 2rem;background: #F6F9FF;border-radius: 8rem;margin-top: 12rem"></div>
              </div>
              <div style="margin-left: 15rem;width: 100%;">
                <div style="padding-left: 15rem;padding-right: 30rem;display: flex;justify-content: space-between;width: 100%">
                  <div style="font-size: 16rem;font-weight: 500;color: #333333;">是否有心理障碍</div>
                  <div style="font-size: 16rem;font-weight: 400;color: #3D3D3D;">
                    {{ data.psychological_disorders }}
                    <el-icon :class="data.psychological_disorders ==='是'?'el-icon-success':'el-icon-remove'" :style="{marginLeft: '10rem',color:data.psychological_disorders ==='是'?'rgba(35, 195, 67, 1)':'rgba(246, 173, 60, 1)'}" size="16"></el-icon>
                  </div>
                </div>
                <div style="height: 66rem;background: #F6F9FF;border-radius: 4rem;padding: 12rem;font-size: 14rem;font-weight: 400;color: #666666;margin-top: 12rem">
                  {{ data.psychological_disorders_details }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div style="margin-top: 24rem;min-width: 1600rem;width: calc(100% - 36rem);height: 598rem;background: #FFFFFF;border-radius: 8rem;padding: 36rem;display: flex;flex-wrap: wrap">
      <div v-for="item in body" style="width: 550rem;height: 108rem;background: #F6F9FF;border-radius: 6rem;margin-right: 60rem;margin-bottom: 30rem">
        <div style="display: inline-block;width:auto;height: 38rem;background: #326FFF;border-radius: 8rem;color: #ffffff;font-weight: 500;text-align: center;line-height: 38rem;padding: 0 20rem">{{ item.title }}</div>
        <div v-if="data[item.key]" style="padding-top: 14rem;padding-left: 19rem;padding-right: 9rem;font-weight: 400;font-size: 14rem;color: #333">
          {{ data[item.key] }}
        </div>
        <div v-else style="width: 100%;display: flex;flex-flow: column;align-items: center;justify-content: center;color:rgba(153, 153, 153, 1);margin-top: -16rem;font-size: 14rem">
          <img :src="OSS_URL+'/bf-static/1v1-taro/heart-to-heart-talk/no-data.png'" alt="" style="width: 32rem;height: 32rem;margin-bottom: 10rem">
          暂无数据...
        </div>
      </div>
    </div>

    <div style="width: 100%;height: 50rem;background: #FFFFFF;display: flex;justify-content: center;align-items: center;color: rgba(90, 109, 229, 1);margin-top: 96rem;cursor: pointer" @click="$router.back()">
      <img :src="OSS_URL+'/bf-static/1v1-taro/heart-to-heart-talk/back.png'" alt="" style="width: 16rem;height: 16rem;margin-right: 4rem">
      返回
    </div>
  </div>
	<div v-else style="background: #f7f7f7">
		<div style="width: 99%;height: 349px;background: #FFFFFF;border-radius: 8rem;display: flex;align-items: center">
			<t-result style="" text="还没有数据提交上来哦" type="empty"></t-result>
		</div>
	</div>
</template>

<script>
import * as url from "url";
import da from "element-ui/src/locale/lang/da";
import TResult from "@/components/Result.vue";


export default {
	components: {TResult},
	_config:{"route":{"path":"edit","meta":{"title":"查看","style":0}}},
  name: "edit",
  computed: {
    da() {
      return da
    },
    url() {
      return url
    }
  },
  data() {
    return {
      data: {},
      OSS_URL: process.env["VUE_APP_URL_PROD_BASE"],
      fields: [
        {
          label: '性别',
          iconClass: 'el-icon-male',
          iconColor: 'rgba(28, 78, 253, 1)',
          key: 'student_gender_text',
          keyType: 'student_gender'
        },
        {
          label: '学号',
          key: 'student_no'
        },
        {
          label: '校区',
          key: 'school'
        },
        {
          label: '行政班',
          key: 'class_name'
        },
        {
          label: '年级',
          key: 'grade'
        },
        {
          label: '谈话人',
          key: 'teacher'
        },
        {
          label: '状态',
          key: 'status_text',
          keyType: 'status'
        },
      ],
      body: [{
        title: '各科目学习情况',
        key: 'subject_study'
      }, {
        title: '学生高考目标',
        key: 'student_target'
      }, {
        title: '职业规划',
        key: 'career_planning'
      }, {
        title: '优点自评、缺点剖析',
        key: 'advantage_shortcoming'
      }, {
        title: '同伴关系',
        key: 'companion_relationship'
      }, {
        title: '寝室关系',
        key: 'dorm_relationship'
      }, {
        title: '师生关系',
        key: 'teacher_student_relationship'
      }, {
        title: '家庭情况',
        key: 'home_details'
      },]
    }
  },
  created() {
    this.$_axios2.get('/api/work/teacher-class/talk-details?id=' + this.$route.query.id).then(res => {
      this.data = res.data.data
    })
  }
}
</script>

<style scoped lang="scss">
.title {
	width: 100%;
	padding: 0 12rem;
	margin: auto;

	.title_info {
		width: 100%;
		height: 56rem;
		background: #F3F7FF;
		border-radius: 5rem;
		line-height: 56rem;
		display: flex;
		justify-content: space-between;
		padding: 0 48rem 0 24rem;
		font-size: 16rem;
		color: #4E5969;
	}
}

.main {
	width: 100%;
	min-height: 100rem;
	padding: 16rem 12rem;
	background: #f7f8fa;
	margin-top: 24rem;

	.main_item {
		width: 100%;
		height: 400rem;
		background: #FFFFFF;
		border-radius: 5rem;
		padding: 27rem;
		margin-bottom: 24rem;
	}
}
</style>
